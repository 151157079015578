import * as React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { IconGlyph, icons } from '@danfoss/etui-core';
import { useXmlResource, useAuth, useUnit } from '@danfoss/etui-sm/context';
import {
  fetchDevicesByUnits,
  Device,
  User,
  Unit,
  getValidDeviceData,
} from '@danfoss/etui-sm-xml';
import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml/constants';
import {
  getFlattenDevices,
  getGroupedDevicesByDeviceType,
} from '@danfoss/etui-sm/utils';
import { routePaths } from 'routePaths';
import { getEquipmentIdByDeviceIdAndDeviceType, getIsEqual } from 'utils';
import { DeviceInfoColumn, ValueColumn } from 'components/EquipmentTable';
import { BaseTile } from './BaseTile';

const glyphByType = {
  [XML_DEVICE_LIST.REFRIG]: icons.REFRIGERATION,
  [XML_DEVICE_LIST.HVAC]: icons.HVAC,
  [XML_DEVICE_LIST.MISC]: icons.MISC,
  [XML_DEVICE_LIST.LIGHTS]: icons.LIGHTING,
  [XML_DEVICE_LIST.METER]: icons.ELECTRICS,
};
export interface DeviceTileProps {
  height: number;
  type: XML_DEVICE_LIST;
  isMaximized: boolean;
  rowCount: number;
  onMaximize: (type: XML_DEVICE_LIST) => void;
  onMinimize: () => void;
  isWidget?: boolean;
}

async function fetchDevices([_, units, type, user, url, skipUpdateSession]: [
  string,
  Unit[],
  XML_DEVICE_LIST,
  User,
  string,
  boolean,
]) {
  return fetchDevicesByUnits([url, units, type, user, skipUpdateSession]);
}

const DeviceTile = React.memo(function DeviceTile({
  height,
  type,
  isMaximized,
  rowCount,
  onMaximize,
  onMinimize,
  isWidget = false,
}: DeviceTileProps) {
  const { t } = useTranslation();
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const { units } = useUnit();
  const defrostState = 's14';
  const skipUpdateSession = true;

  const { data, error, isValidating, mutate } = useSWR(
    ['deviceTile', units, type, user, xmlBackendURL, skipUpdateSession],
    fetchDevices,
    {
      refreshInterval: 15000,
    },
  );

  const validData =
    type === XML_DEVICE_LIST.MISC ? getValidDeviceData(data) : data;
  if (!validData?.length) return null;

  const getIconByType = (): IconGlyph => glyphByType[type];

  const getTitleByType = () =>
    type === XML_DEVICE_LIST.REFRIG
      ? t('t150')
      : type === XML_DEVICE_LIST.HVAC
      ? t('t87')
      : type === XML_DEVICE_LIST.MISC
      ? t('t190')
      : type === XML_DEVICE_LIST.LIGHTS
      ? t('t109')
      : type === XML_DEVICE_LIST.METER
      ? t('t374')
      : '';

  const getDeviceShowStatusPath = (device: Device) => {
    const deviceType =
      type === XML_DEVICE_LIST.REFRIG
        ? 'refrig'
        : type === XML_DEVICE_LIST.HVAC
        ? 'hvac'
        : type === XML_DEVICE_LIST.MISC
        ? 'misc'
        : type === XML_DEVICE_LIST.LIGHTS
        ? 'lights'
        : type === XML_DEVICE_LIST.METER
        ? 'meter'
        : 'refrig';

    const groupedDevices = getGroupedDevicesByDeviceType(deviceType, validData);
    const devices = getFlattenDevices(groupedDevices);

    const item = devices.find(d => d.name === device.name);
    if (item) {
      const equipmentId = getEquipmentIdByDeviceIdAndDeviceType(
        item.id,
        deviceType,
      );
      return routePaths.pages.equipment.showStatus(equipmentId);
    }

    return '';
  };
  const isRefrigDevice = type === XML_DEVICE_LIST.REFRIG;
  const standardColumnWidth = isRefrigDevice ? '20%' : '25%';
  const deviceNameColumnWidth = isRefrigDevice ? '36%' : '45%';
  const columns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      width: deviceNameColumnWidth,
      ellipsis: true,
      render: (value, record: Device) => {
        return (
          <DeviceInfoColumn
            size="small"
            name={value}
            host={record.host}
            addr={record.addr}
            indent={record.indent}
            deviceProductValue={false}
          />
        );
      },
    },
    {
      title: t('t77'),
      dataIndex: 'value',
      width: standardColumnWidth,
      ellipsis: true,
      render: value => {
        value = value !== undefined ? value.replace('OffLn', '') : null;
        return <ValueColumn value={value} width="auto" />;
      },
    },
    {
      title: t('t42'),
      dataIndex: 'status',
      width: standardColumnWidth,
      ellipsis: true,
      render: (value, record: Device) => (
        <ValueColumn
          value={value}
          isDefrosting={
            record?.defrost === '1' || value?.includes(defrostState)
          }
          hasAlarm={record?.alarm === '1'}
          width="auto"
        />
      ),
    },
  ];
  if (isRefrigDevice) {
    columns.push({
      title: t('t161'),
      dataIndex: 'ctrl_val',
      width: '20%',
      ellipsis: true,
      render: value => <ValueColumn value={value} width="auto" />,
    });
  }
  const title = getTitleByType();

  return (
    <BaseTile
      rowKey={(record: Device, index) => `${record.name}-${index}`}
      rowPropsGetter={(record: Device) => ({
        to: getDeviceShowStatusPath(record),
      })}
      height={height}
      title={title}
      icon={getIconByType()}
      isWidget={isWidget}
      columns={columns}
      rowCount={rowCount}
      data={validData}
      error={error}
      emptyStateText={t('t892', { type: title.toLowerCase() })}
      isValidating={isValidating}
      isMaximized={isMaximized}
      onMaximize={() => onMaximize(type)}
      onMinimize={onMinimize}
      onRefreshClick={mutate}
      onTableRowClick={getDeviceShowStatusPath}
    />
  );
},
getIsEqual);

export { DeviceTile };
